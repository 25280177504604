import Component from './component'

export default class Menu extends Component{
	ready(){
		super.ready();
		//nav .menu-item-has-children
		jQuery("nav .nav-menu>ul>li.menu-item-has-children > a").click(function(e){
			var isOpen = jQuery(this).hasClass("open");
			jQuery("nav .nav-menu>ul>li.menu-item-has-children > ul").slideUp(300);
			jQuery("nav .nav-menu>ul>li.menu-item-has-children > ul").removeClass("open");
			jQuery("nav .nav-menu>ul>li.menu-item-has-children > a").removeClass("open");

			if(!isOpen){
				jQuery(this).parent().find("> ul").stop(true, true).slideDown(300);
				jQuery(this).parent().find("> ul").addClass("open");
				jQuery(this).addClass("open");
			}

			return false;
		});

		//mobile
		jQuery('header .hamburger-menu').on('click', function() {
			jQuery('nav').toggleClass('open');
			jQuery('html').toggleClass('no-scroll');
			jQuery('header .bar').toggleClass('animate');
			window.menuToggle();
		});

		//desktop
		jQuery('.site > .overlay').on('click', function() {
			jQuery('body').toggleClass('hidebar');
			jQuery('nav').removeClass('open');
			jQuery('nav .bar').removeClass('animate');
			jQuery('.site > .overlay').removeClass('open');
			jQuery('.button-holder.scholen').removeClass('fade');

		});
		jQuery('nav .open-main-menu').on('click', function() {
			jQuery('body').toggleClass('hidebar');
			jQuery('nav').toggleClass('open');
			jQuery('nav .bar').toggleClass('animate');
			jQuery('.site > .overlay').toggleClass('open');
			jQuery('.button-holder.scholen').toggleClass('fade');
		});

	}
	load(){
		new MobileMenu("nav.nav-main-menu");
	}
}

function MobileMenu(selector){
	var self = jQuery(selector);
	if(!(jQuery(selector).length>0))return;
	var backup = self.clone();
	var me = this;
	var active = false;
	var menuitems=[];
	var menuStack=[];
	var delayTime = 40;
	var animationTime = 400;
	var mobileSize = 750;
	var offsetDelay=0;

	var dragging=false;
	var lastTouchX=0;
	var lastTouchY=0;
	var touchOffsetX=0;
	var touchOffsetY=0;
	function resize(){
		var w = jQuery(window).width();
		//if(w <= mobileSize && active == false ){
		if(w <= mobileSize ){
			active = true
			self.replaceWith(backup.clone());
			self = jQuery("nav.nav-main-menu");

			jQuery('.menu-button .bar').removeClass('animate');
			//            jQuery('.circle').removeClass('expand');
			jQuery('header nav.nav-main-menu').removeClass('open');
			jQuery('html').removeClass('no-scroll');

			start();
			return;
		}
		if(w > mobileSize && active == true ){
			active = false;
			self.replaceWith(backup.clone());
			// Menu
			jQuery(".menu-item-has-children").hover(function(){
				//hover in
				if(!jQuery("html").hasClass("no-scroll"))
				jQuery(this).find("> ul").stop(true, true).slideDown(300);
			}, function(){
				//hover out
				if(!jQuery("html").hasClass("no-scroll"))
				jQuery(this).find("> ul").stop(true, true).slideUp(300);
			});
		}
	}

	function create() {
		window.menuToggle = function(){
			menuStack = [];
			menuStack.push(0);
			offsetDelay = 210;
			showMenuItems(0,false)
			centerItems(0, true, false);
			offsetDelay = 0;
			var menuID=0;
			self.find(".nav-menu .menu-item-has-children").each(function(){
				menuID++;
				hideItems(menuID, false, false);
			});
		};
		jQuery(window).resize(function(e){
			resize();
		});
		resize();

		jQuery(window).bind('touchstart', touchstart.bind(this));
		jQuery(window).bind('touchend', touchend.bind(this));
		jQuery(window).bind('touchmove', touchmove.bind(this));

	}

	function touchstart(e){
		if(jQuery("nav.nav-main-menu").hasClass("open")){
			lastTouchX = e.originalEvent.changedTouches[0].screenX;
			lastTouchY = e.originalEvent.changedTouches[0].screenY;
			dragging = true;
		}
	}

	function touchend(e){
		if(jQuery("nav.nav-main-menu").hasClass("open")){
			if(jQuery("nav.nav-main-menu").hasClass("open")){
				lastTouchX = 0;
				lastTouchY = 0;
				dragging = false;
			}
		}
	}

	function touchmove(e){
		if(dragging){
			//            console.log(e);
			touchOffsetX += e.originalEvent.changedTouches[0].screenX - lastTouchX;
			touchOffsetY += e.originalEvent.changedTouches[0].screenY - lastTouchY;

			lastTouchX = e.originalEvent.changedTouches[0].screenX;
			lastTouchY = e.originalEvent.changedTouches[0].screenY;

			jQuery("#primary-menu .menu-item.centered").css("transform","translate("+touchOffsetX+"px,"+touchOffsetY+"px)");
		}
	}

	update();
	function update(){
		if(jQuery("nav.nav-main-menu").hasClass("open")){
			if(!dragging){
				var closeY=500;
				jQuery("#primary-menu .menu-item.centered").each(function(){
					var c = parseInt(jQuery(this).css("top"));
					if(c<closeY)closeY=c;
				});

				touchOffsetX = touchOffsetX / 1.1;
				if(closeY<0){
					if(touchOffsetY > -closeY) touchOffsetY = touchOffsetY / 1.1;
					if(touchOffsetY < closeY) touchOffsetY = touchOffsetY / 1.1;
				}else{
					touchOffsetY = touchOffsetY / 1.1;
				}


				// 0.5*schermH

				jQuery("#primary-menu .menu-item.centered").css("transform","translate("+touchOffsetX+"px,"+touchOffsetY+"px)");
			}
		}
		window.requestAnimationFrame(update.bind(this));
	}


	function start() {
		menuitems=[];
		menuStack=[];

		//backbutton
		self.append("<div class='backbutton'><div class='icon'></div></div>");

		self.find(".backbutton").click(function(){
			closeMenu();
		});

		//Get main menu
		var menuID = 0;
		menuStack.push(0);
		menuitems[menuID] = [];
		self.find(".nav-menu > ul > li").each(function(){
			jQuery(this)[0].defaultWidth = jQuery(this).width();
			menuitems[0].push(jQuery(this));

		});

		//center main menu
		centerItems(0, false, false);

		//get other menus
		self.find(".nav-menu .menu-item-has-children").each(function(){
			menuID++;
			jQuery(this).attr('data-menu-id',''+menuID);
			var me = jQuery(this);
			var clone = jQuery(this).clone();
			clone.appendTo(self.find(".nav-menu"));
			clone.removeClass('menu-item-has-children');
			clone.removeClass('current_page_ancestor');
			clone.addClass('submenu');
			clone.find('ul').remove();
			clone[0].defaultWidth = clone.width();
			menuitems[menuID] = [];
			menuitems[menuID].push(clone);

			jQuery(this).find('li').each(function(){
				if(jQuery(this).parent().parent().attr('data-menu-id')==menuID){
					jQuery(this).addClass('submenu');
					jQuery(this).detach().appendTo(self.find(".nav-menu"));
					jQuery(this)[0].defaultWidth = jQuery(this).width();
					menuitems[menuID].push(jQuery(this));
				}
			});
			//jQuery(this).find('ul').detach();

			jQuery(this).click(function(e){
				e.cancelBubble = true;
				e.stopPropagation();
				openMenu(jQuery(this).attr('data-menu-id'));
				return false;
			});
			hideItems(menuID, false, false);
		});
	}

	function openMenu(menuID){
		showMenuItems(menuID,true)
		if(menuStack[menuStack.length-2]!=undefined)
		hideItems(menuStack[menuStack.length-2],true,true);
		toLeftItems(menuStack[menuStack.length-1], true);
		centerItems(menuID, true, true);
		menuStack.push(menuID);
		touchOffsetX = touchOffsetY = 0;
	}

	function closeMenu(){
		if(menuStack[menuStack.length-3]!=undefined){
			showMenuItems(menuStack[menuStack.length-3],true);
			toLeftItems(menuStack[menuStack.length-3], true);
		}
		centerItems(menuStack[menuStack.length-2],true, false);
		hideItems(menuStack[menuStack.length-1],true, false);
		menuStack.pop();
		touchOffsetX = touchOffsetY = 0;
	}

	function showMenuItems(menuID,animate){
		if(menuitems[menuID]==undefined)return;
		for(var i=0;i<menuitems[menuID].length;i++){
			//menuitems[menuID][i].css('width','');
			var newW = menuitems[menuID][i][0].defaultWidth;
			newW = 210;
			if(animate){
				menuitems[menuID][i].find(">a").delay(i*delayTime).animate({opacity:1},animationTime);
			}else{
				menuitems[menuID][i].find(">a").css('opacity','1');
				menuitems[menuID][i].css('width',newW);
			}
			menuitems[menuID][i].css('pointer-events','auto');

		}
	}

	function hideItems(menuID, animate, alt){
		if(menuitems[menuID]==undefined)return;
		var items = menuitems[menuID].length;
		var screenWidth = jQuery(window).width();
		for(var i=0;i<items;i++){
			//menuitems[menuID][i].css('width','');
			var itemWidth = menuitems[menuID][i].width();
			var itemHeight = menuitems[menuID][i].height();
			var newW = menuitems[menuID][i][0].defaultWidth;
			newW = 210;
			menuitems[menuID][i].removeClass('centered');
			menuitems[menuID][i].css("transform","none");
			if(animate){
				if(alt) menuitems[menuID][i].delay(i*delayTime).animate({left:-itemWidth,width:newW},animationTime);
				else menuitems[menuID][i].delay(i*delayTime).animate({left:screenWidth+itemWidth,width:newW},animationTime);
				menuitems[menuID][i].find(">a").delay(i*delayTime).animate({opacity:0},animationTime);
			}else{
				menuitems[menuID][i].offset({left:-itemWidth});
				menuitems[menuID][i].find(">a").css('opacity',0);
				menuitems[menuID][i].css('width',newW);
			}
			menuitems[menuID][i].css('pointer-events','none');
		}
	}

	function toLeftItems(menuID, animate){
		if(menuitems[menuID]==undefined)return;
		var items = menuitems[menuID].length;
		var screenWidth = jQuery(window).width();
		var screenHeight = jQuery(window).height();
		for(var i=0;i<items;i++){
			var itemWidth = menuitems[menuID][i].width();
			var itemHeight = menuitems[menuID][i].height();
			var newW = menuitems[menuID][i][0].defaultWidth;

			itemWidth = newW;
			var x = (-itemWidth)+50;
			var y = ((screenHeight*0.5) - (itemHeight*0.5*items)) + (i*itemHeight);
			newW = 210;
			//console.log(((newW - itemWidth )*0.5));
			x -= ((newW - itemWidth )*0.5);
			menuitems[menuID][i].removeClass('centered');
			menuitems[menuID][i].css("transform","none");
			if(animate){
				menuitems[menuID][i].delay(i*delayTime).animate({top:y,left:x,width:newW},animationTime);
			}else{
				menuitems[menuID][i].offset({top:y, left:x});
				menuitems[menuID][i].css('width',newW);
			}
		}
	}

	function centerItems(menuID, animate, alt){
		if(menuID==0) jQuery('.backbutton').removeClass('show');
		else jQuery('.backbutton').addClass('show');

		if(menuitems[menuID]==undefined)return;
		var items = menuitems[menuID].length;
		var screenWidth = jQuery(window).width();
		var screenHeight = jQuery(window).height();
		var totalItemHeight=0;
		for(var i=0;i<items;i++){
			totalItemHeight+=menuitems[menuID][i].height();
		}
		for(var i=0;i<items;i++){

			var itemWidth = menuitems[menuID][i].width();
			itemWidth=210;
			var itemHeight = menuitems[menuID][i].height();
			var currentItemHeight = 0;
			for(var j=0;j<i;j++){
				currentItemHeight+= menuitems[menuID][j].height();
			}
			//console.log(itemHeight);
			var x = (screenWidth*0.5) - (itemWidth*0.5);
			var y = ((screenHeight*0.5) - (totalItemHeight*0.5))+currentItemHeight;
			//var y = ((screenHeight*0.5) - (itemHeight*0.5*items)) + (i*itemHeight);
			var newW = screenWidth - 140;
			newW = 210;

			menuitems[menuID][i].addClass('centered');

			if(animate){
				if(alt)     menuitems[menuID][i].offset({top:y, left:screenWidth+itemWidth});
				else        menuitems[menuID][i].offset({top:y, left:-itemWidth});
				menuitems[menuID][i].delay(offsetDelay + (i*delayTime)).animate({top:y,left:x, width:newW},animationTime);
			}else{
				menuitems[menuID][i].offset({top:y, left:x});
				menuitems[menuID][i].css('width',newW);
				//menuitems[menuID][i].css('transform','translate3d('+x+'px,'+y+'px,0)')
			}
		}
	}
	create();
}
