// Components
import Menu from './components/menu'
import Utils from './components/utils'

new Menu();
new Utils();

// Effects
import SlowParallax from './components/slowparallax'
import Parallax from './components/parallax'
import OnScreen from './components/onscreen'

//new Parallax("header .background", false);

new OnScreen(".onscreen", 0);
