export default class Component{
    constructor(){
        jQuery(window).ready(this.ready.bind(this));
        jQuery(window).load(this.load.bind(this));
    }
    ready(){
    }
    load(){
    }
}
