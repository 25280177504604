import Component from './component'

export default class Utils extends Component{
    ready(){
        super.ready();

		  $('.scholen p').contents().unwrap();

        jQuery(window).scroll(this.scroll.bind(this));


            jQuery('#goToPrevSlide').on('click', function () {
                slider.goToPrevSlide();
            });
            jQuery('#goToNextSlide').on('click', function () {
                slider.goToNextSlide();
            });

        //mobile buttons instant klik

        //jQuery('.mod-touch .menu-item a').bind('touchend', function(event) {
        //jQuery('.mod-touch .menu-item a').bind('tap', function(event) {
            //window.location.href = $(this).attr("href");
        //});

        //oponoa scholen popup
        jQuery("a.oponoa-popup").click(function(e){
            jQuery(".oponoa-scholen-popup").toggleClass("open");
            e.preventDefault();
            return false;
        });
        jQuery(" .oponoa-scholen-popup").click(function(e){
            if(jQuery(e.target).hasClass("oponoa-scholen-popup")||jQuery(e.target).hasClass("close-popup")){
                jQuery(".oponoa-scholen-popup").toggleClass("open");
            }
        });

        //fancybox the images
        jQuery("a:has(img)").attr('rel', 'gallery').fancybox();

        //find closest link
        jQuery(".proxi-link").click(function(e){
            window.location.href = jQuery(this).find("a").attr('href');
            e.preventDefault();
        });

        //slow scroll top
        jQuery('a[href="#top"]').click(function (e) {
        	jQuery('body,html').animate({scrollTop: 0}, 500);
             e.preventDefault();
        	return false;
        });

        //left menu
        jQuery("section.text div.container").each(function(){
            var h = jQuery(this).find("aside").height();
            jQuery(this).find("article").css("min-height",(h+80+80)+"px");
        });

        //Better form submit button
		  /*
        jQuery("input[type='submit']").each(function(){
            jQuery(this).hide();
            jQuery(this).after("<div class='button third auto-submit'>"+jQuery(this).val()+"</div>");
            //jQuery("#gform_1").trigger("submit",[true]); }
        });

            //form submit
        jQuery(".auto-submit").click(function(e){
            console.log("submit");
            console.log(jQuery(this).closest("form").first());
            jQuery(this).closest("form").first().submit();
        });
		  */
/*

        //change form file upload
        jQuery('form input[type="file"]').each(function(e){
            var me = jQuery(this);
            var label = me.parent().parent().find("label")
            var name = label.text();
            var preview = me.parent().find(".ginput_preview").length>0;
            label.remove();
            me.closest(".field_sublabel_below").css('clear','both');
            me.parent().append("<div class='replacement-file-upload'><div class='button'>+ "+name+"</div><div class='text'></div></div>");
            if(!preview)
            jQuery(this).parent().find('.text').text("Er zijn nog geen bestanden geüpload");
            me.parent().find('.replacement-file-upload .button').click(function(e){
                me.trigger('click');
                e.preventDefault();
            });
            me.change(function(e){
                jQuery(this).parent().find('.text').html("Geüpload: <span>"+me.val().replace(/^.*[\\\/]/, '')+"</span>");
            });
            me.addClass('form-hide');
        });
*/
        //textarea fix
        jQuery("textarea").wrap( "<div class='textarea-holder'></div>" );
        jQuery("textarea").focusin( function(){
            jQuery(this).parent().addClass('focus');
        } );
        jQuery("textarea").focusout( function(){
            jQuery(this).parent().removeClass('focus');
        } );

    }

    scroll(event){
        var t = jQuery(window).scrollTop();
        if(t>500){
            jQuery('.scroll-up').addClass("popup");
        }else{
            jQuery('.scroll-up').removeClass("popup");
        }
    }
}
