import Effect from './effect'

export default class SlowParallax extends Effect{
    constructor(selector, factor){
        super(selector);
        this.factor = factor;
    }
    ready(){
        super.ready();
    }

    scroll(event){
        var pos = this.factor * jQuery(window).scrollTop();

        //clamp
        if(this.factor>0){
            if(pos<0)pos=0;
        }else{
            //if(pos>0)pos=0;
        }

        //not on mobile
        if (jQuery(window).width() < 768) {
            pos=9;
        }
        var hor = "0";
        hor = parseInt(this.self.css('background-position').split(" ")[0]);
        if(isNaN(hor))hor=0;
        this.self.css("background-position",hor+"px "+pos+"px")
    }
}
