
import Effect from './effect'

export default class Parallax extends Effect{
    constructor(selector, flip){
        super(selector);
        this.flip = flip;
        this.mobile=false;
    }
    ready(){
        super.ready();
        if(jQuery("html").hasClass("mod-touch"))this.mobile = true;
        this.update();
    }

    scroll(e){
        this.update();
    }

    update(){
        if(!this.mobile){
            var scroll = jQuery(document).scrollTop();
            var screen_start; var screen_end;
            var div_start; var div_end;
            var factor_start; var factor_end;
            var delta; var factor;

            screen_start = scroll;
            screen_end = screen_start+jQuery( window ).height();
            div_start = this.self.offset().top;
            div_end = div_start+this.self.height();
            factor_end = div_end-screen_start;
            factor_start = div_start-screen_end;
            delta = factor_start - factor_end;
            factor = factor_start / Number(delta);
            if(factor<0)factor=0;
            if(factor>1)factor=1;

            if(!this.flip){
                factor = 1-factor;
            }

            this.self.css('background-position','0% '+(factor*100)+'%')
        }
    }
}
