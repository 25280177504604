import Effect from './effect'

export default class OnScreen extends Effect{
    constructor(selector, offset){
        super(selector);
        this.offset = offset;
    }

    ready(){
        super.ready();
        this.update();
    }

    scroll(e){
        this.update();
    }

    update(){
        var offset = this.offset;
        jQuery(this.selector).each(function(){
            var bWindow = jQuery(window).scrollTop()+ jQuery(window).height();
            var tWindow = jQuery(window).scrollTop();
            var h = jQuery(this).offset().top;
            var b = jQuery(this).offset().top + jQuery(this).height();
            //remove 'animate'

            if(jQuery(this).hasClass('replay') && jQuery(this).hasClass('animate')){
                if(bWindow<(h+offset+10) || tWindow >(b-offset-10)){
                    jQuery(this).removeClass('animate');
                }

            };
            //add 'animate'
            if(jQuery(this).hasClass('animate')) return;
            if(bWindow>(h+offset) && tWindow<(b-offset)){
                var delay = jQuery(this).attr("data-delay");
                if(delay==undefined) delay=1;
                setTimeout(  () => { jQuery(this).addClass('animate'); }  , delay);
            }
        });
    }
}
