import Component from './component'

export default class Effect extends Component{
    constructor(selector){
        super();
        this.ready = false;
        this.selector = selector;
    }
    ready(){
        if(this.ready)
            super.ready();

        this.self = jQuery(this.selector);
        if(jQuery(this.selector).length>0){
            this.ready = true;
            jQuery(window).scroll(this.scroll.bind(this));
        } else {
            //console.log("Effect Selector Error");
        }
    }
    load(){
        if(this.ready)
            super.load();
    }
    scroll(event){
    }
}
